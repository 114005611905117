@import '../../../shared/assets/styles/normalize';
@import 'assets/styles/variables';

html {
  height: 100%;
  min-height: 100%;
}

body {
  height: 100%;
  min-height: 100%;
  font-family: $default-font-family;
  font-weight: $default-font-weight;
  font-size: $default-font-size;
  line-height: $default-line-height;
  -webkit-text-size-adjust: none;
  color: $content-color;
  background: #000;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;
  background: $content-bg;
}

main {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 6.5% 4% 30px;
  margin-top: 50px;
  text-align: center;

  @media (min-width: $screen-sm-min) {
    padding-top: 40px;
    padding-bottom: 50px;
  }

  @media (min-width: $screen-md-min) {
    margin-top: 80px;
  }

  > * {
    width: 100%;
  }
}

footer {
  background-color: #1d2701;
  color: #acb33c;
}

.header-wrapper {
  color: #6d9604;
}

th,
td {
  border: 1px solid #515151;
  padding: 8px;
  text-align: center;
}

em {
  font-weight: 600;
  color: $primary;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
  color: $primary;
  text-transform: uppercase;
}

h4 {
  font-size: 16px;
  font-weight: 600;
  color: $dark;
}

h6 {
  font-weight: 600;
  color: $primary;
}

h2,
h3,
h4 {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

p a {
  color: $primary;
  text-decoration: underline;

  &:hover {
    color: #a4a4a4;
  }
}

* + .h4 {
  margin-top: 17px;
}

* + h4 {
  margin-top: 28px;
}

* + h6 {
  margin-top: 15px;
}

* + p {
  margin-top: 10px;
}

* + svg {
  margin-top: 10px;
}

* + form {
  margin-top: 10px;
}

* + .link {
  margin-top: 20px;
}

* + .label {
  margin-top: 10px;
}

p + .link {
  margin-top: 30px;
}

svg + h4 {
  margin-top: 22px;
}

br + .link {
  margin-top: 30px;
}

.value + ul {
  margin-top: 22px;
}

.divider + .label {
  margin-top: 15px;
}

.divider + form {
  margin-top: 15px;
}

.inline-block + .divider {
  margin-top: 18px;
}

.value + input {
  margin-top: 15px;
}

.value + .label {
  margin-top: 20px;
}

* + .inline-block {
  margin-top: 10px;
}

.link + .marked-list {
  margin-top: 5px;
}

img + .marked-list {
  margin-top: 5px;
}

.marked-list + img {
  margin-top: 20px;
}

.divider + h4 {
  margin-top: 22px;
}

.container {
  position: relative;
  width: 100%;
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $screen-md-min) {
    max-width: 96%;
  }

  @media (min-width: $screen-xl-min) {
    max-width: 1300px;
  }
}

.slide-up-1,
.slide-up-2,
.slide-up-3 {
  opacity: 0;
  transform: translateY(800px);
  transition: 0.3s all ease;
}

.modal-enter,
.modal-exit {
  opacity: 0;
}

.modal-enter-done {
  opacity: 1;
  transition: 0.2s opacity ease;

  .slide-up-1,
  .slide-up-2,
  .slide-up-3 {
    opacity: 1;
    transform: none;
    transition-delay: 0.5s;
  }

  .slide-up-1 {
    transition-delay: 0.3s;
  }

  .slide-up-2 {
    transition-delay: 0.4s;
  }

  .slide-up-3 {
    transition-delay: 0.5s;
  }
}

.slide-enter,
.slide-exit-active {
  opacity: 0;
  transform: translateY(-100%);
}

.slideup-enter,
.slideup-exit-active {
  opacity: 0;
  transform: translateY(100%);
}

.slide-enter-active,
.slideup-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition:
    opacity 300ms,
    transform 300ms;
}

.slide-exit,
.slideup-exit {
  opacity: 1;
}

.slide-exit-active,
.slideup-exit-active {
  transition:
    opacity 300ms,
    transform 300ms;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

body .d-block {
  display: block;
}

button.d-block {
  width: 100%;
}

.d-i-block {
  display: inline-block;
}

.d-none {
  display: none;
}

.hidden-mobile {
  @media (max-width: ($screen-sm-min - 1)) {
    display: none;
  }
}

.visibility-hidden {
  visibility: hidden;
}

.color-primary {
  color: $primary;
}

.fill-primary {
  fill: #40cf75;
}

.stroke-primary {
  stroke: #40cf75;
}

.color-red {
  color: $red;
}

.divider {
  margin-top: 26px;
  text-align: center;
}

.marked-list {
  text-align: left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  > li {
    position: relative;
    padding-left: 12px;

    &:before {
      content: '';
      position: absolute;
      top: 8px;
      left: 2px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: $primary;
    }

    @media (min-width: $screen-md-min) {
      margin-top: 8px;
    }
  }
}

.inline-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bottom-block {
  margin-top: auto;
  padding-top: 40px;
}

.label,
.value {
  font-weight: 600;
}

.label {
  display: inline-block;
  color: $dark;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.value {
  font-size: 16px;
  color: $primary;

  a,
  span {
    vertical-align: middle;
  }

  a {
    display: inline-block;
    padding: 0 0 0 5px;
    line-height: 0;
  }

  a + span {
    margin-left: 5px;
  }
}

.link,
.media {
  display: inline-block;
  padding: 3px;
  font-size: 14px;
  font-weight: 600;

  @media (min-width: $screen-md-min) {
    font-size: 16px;
  }

  svg,
  span,
  img {
    vertical-align: middle;
  }

  svg,
  img {
    margin-right: 7px;
  }

  &.primary {
    color: $primary;
  }
}

.link {
  cursor: pointer;
  transition: 0.3s color ease;

  &:active {
    color: #a4a4a4;
  }

  &.primary {
    color: $primary;

    &:active {
      color: lighten($primary, 10%);
    }
  }

  span {
    text-decoration: underline;
  }

  @media (min-width: $screen-md-min) {
    &:hover {
      color: #a4a4a4;

      &.primary {
        color: lighten($primary, 10%);
      }
    }
  }
}

.phone {
  display: inline-block;
  padding: 4px;
  margin-top: 7px;
  font-size: 16px;
  font-weight: 600;
  color: $primary;
  cursor: pointer;

  svg,
  span {
    vertical-align: middle;
  }

  svg {
    margin-left: 10px;
    margin-top: 0;
  }

  span {
    text-decoration: underline;
  }
}

.error-message {
  display: inline-block;
  margin-top: 18px;
  text-align: left;
  color: #cb362e;

  h6 {
    color: inherit;
  }

  .marked-list li:before {
    background-color: #cb362e;
  }
}

.screen-keyboard {
  @media (min-height: 640px) {
    //max-height: calc(100vh - 385px);
  }

  @media (min-height: 820px) {
    max-height: calc(100vh - 434px);
  }

  @media (max-height: 800px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  > div.container {
    min-height: 0;

    @media (max-height: 1000px) {
      margin-top: 30px;
    }
  }

  & + footer {
    margin-bottom: 240px;

    @media (min-height: 820px) {
      margin-bottom: 288px;
    }
  }
}

.button-paste {
  display: block;
  margin-top: 20px;
  background-color: #8a8a8a;

  &:active {
    background-color: #a1a1a1;
  }

  @media (min-width: $screen-md-min) {
    min-width: 130px;
    font-size: 14px;
    &:hover {
      background-color: #a1a1a1;
    }
  }
}

.products-list {
  padding-bottom: 25px;
  li {
    margin-top: 35px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.product {
  padding-bottom: 11%;

  a {
    position: relative;
    overflow: visible;
    &:after {
      content: '';
      position: absolute;
      top: -8%;
      left: -6%;
      right: -6%;
      bottom: -12%;
      background: url('assets/images/frame.png') no-repeat center;
      background-size: 100% 100%;
    }
  }

  @media (min-width: $screen-md-min) {
    a:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
}

.product-button {
  position: absolute;
  left: 50%;
  bottom: -19%;
  width: 20%;
  padding-bottom: 20%;
  transform: translateX(-50%);
  box-sizing: border-box;
  border-radius: 50%;
  background: url('assets/images/btn-bg.jpg') no-repeat center;
  background-size: cover;
  box-shadow: 0 3px 7px 6px rgba(1, 1, 1, 1);
  z-index: 1;
}

.button-primary {
  background-color: $primary;

  &:active:not(.disabled) {
    background-color: lighten($primary, 12%);
  }

  @media (min-width: $screen-md-min) {
    &:hover:not(.disabled) {
      background-color: lighten($primary, 12%);
    }
  }
}

.button-secondary {
  background-color: #8a8a8a;

  &:active:not(.disabled) {
    background-color: #a1a1a1;
  }

  @media (min-width: $screen-md-min) {
    &:hover:not(.disabled) {
      background-color: #a1a1a1;
    }
  }
}

header .button-secondary {
  background-color: #6d9604;

  &:active:not(.disabled) {
    background-color: #4c6803;
  }

  @media (min-width: $screen-md-min) {
    &:hover:not(.disabled) {
      background-color: #4c6803;
    }
  }
}

button.disabled {
  opacity: 0.4;
  cursor: default;
}

.cashin-terminal-button {
  background-color: $primary;
  color: $white;

  &:active:not(.disabled) {
    background-color: lighten($primary, 12%);
  }

  @media (min-width: $screen-md-min) {
    &:hover:not(.disabled) {
      background-color: lighten($primary, 12%);
    }
  }
}

.happy-hour + main > div:first-child {
  @media (max-width: ($screen-xl-min - 1)) {
    padding-top: 92px;
  }
}

.underline {
  text-decoration: underline;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.extra-bold {
  display: inline-block;
  padding: 3px 6px;
  margin-top: -1px;
  background-color: $primary;
  color: $white;
  border-radius: 60px;
  line-height: 1;
  font-weight: 700;
  vertical-align: center;
  text-align: center;
}

h4 .extra-bold {
  padding: 4px 11px;
}

br + .extra-bold {
  margin-top: 4px;
  margin-bottom: 15px;
}

input[type='checkbox'] {
  & + label:before {
    border-color: $primary;
  }

  &:checked + label:before {
    background-color: $primary;
  }
}

.bonus-label {
  background-color: $primary;
}

.games-wrap {
  padding-left: 10px;
  padding-right: 10px;
}

.active-input {
  -webkit-box-shadow: 0 0 4px $primary;
  -khtml-box-shadow: 0 0 4px $primary;
  -moz-box-shadow: 0 0 4px $primary;
  -o-box-shadow: 0 0 4px $primary;
  box-shadow: 0 0 4px $primary;
}

.border-primary {
  border-color: $primary;
}

.overflow-hidden {
  overflow: hidden;
}

.Toastify__toast-icon {
  font-size: 22px;
  margin-right: 16px;
}

.app-toast-success {
  background: $primary !important;
}
